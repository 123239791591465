   .App {
      font-family: sans-serif;
      text-align: center;
   }
   .slider {
      position: relative;
      height: 100vh;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      margin:0;
      padding: 0;
      

   }
   .slide{
      min-width: 50%;
      height: 60vh;;
      font-size: 50px;
   }
   img{
      width: 100%;
      height: 100%;
      object-fit: contain;
   }
   .left-arrow,.right-arrow{
      position: absolute;
      z-index: 1;
      font-size: 30px;
      top: 50%;
   }
   .left-arrow{
      left:5%;
   }
   .right-arrow{
      right:5%
   }